"use client";

import { useEffect } from "react";
import { Layout } from "@components/Layout/index";
import { Render } from "@components/sections";
import { trackEvent } from "@lib/track-event";

export default function HubClient({
  config,
  preview,
  title,
  seo,
  content,
  slug,
  subscribed,
  modal,
  allProducts,
}) {
  const hasEmailCapture = content?.some((section) => section._type === "emailCapture");

  useEffect(() => {
    trackEvent("view_page", {
      pageTitle: title,
      pageCategory: "Landing Page",
      pageLocation: window.location.href,
      pagePath: window.location.pathname,
    });
  }, [title]);

  return (
    <Layout
      config={config}
      seo={seo}
      title={title}
      subscribed={subscribed}
      isPreview={preview}
      pageFound={slug !== null}
      modal={modal}
    >
      <main>
        {content && (
          <Render
            sections={content}
            disclaimer={hasEmailCapture ? modal.disclaimerCopy : null}
            allProducts={allProducts}
          />
        )}
      </main>
    </Layout>
  );
}
